import { Button, Drawer, Row } from "rsuite"

const GenericDrawer = (props) => {
    return  <Drawer
                show={props.modalState}
                onHide={props.closeModal}
                size={props.size ? props.size : 'xs'}
                placement={props.placement ? props.placement : 'right'}
            >
                <Drawer.Header>
                <Drawer.Title>{props.title} {props.titleAction ? props.titleAction : null }</Drawer.Title>
                </Drawer.Header>
                <Drawer.Body>
                    {props.children}
                </Drawer.Body>
                <Drawer.Footer>
                    <Row style={{display: 'flex', justifyContent: 'space-between', width: '100%', position: 'fixed', bottom: 15 }}>
                        <Row style={{ marginRight: "150px" }}>
                            { props.handleDelete ? <Button loading={props.loading || false} onClick={props.handleDelete} appearance="primary" color="red">Deletar</Button> : null}
                        </Row>
                        <Row style={{ marginRight: "20px" }}>
                            { props.handleApply ? <Button loading={props.loading || false} onClick={props.handleApply || props.closeModal} appearance="primary" disabled={props.applyDisabled}>{props.applyLabel || 'Aplicar'}</Button> : null }
                            { !props.hideCancelButton ? <Button onClick={props.closeModal} appearance="subtle">{props.closeLabel || 'Cancelar'}</Button> : null }
                        </Row>
                    </Row>
                </Drawer.Footer>
            </Drawer>
}

export default GenericDrawer;